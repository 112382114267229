import React, { useState } from 'react';
import { ApiCall, pythonUrl } from '../Data/functions';
import {useCookies} from 'react-cookie'

const PdfAuditComponent = () =>
{
    const [fileName,setFileName] = useState("abc");
    const [files,setFiles] = useState([]);
    const [table,setTable] = useState(<></>);
    const [loadin,setloadin] = useState("");

    const [cookies, setCookie, removeCookies] = useCookies();
    const generateFiles = async (e) =>
    {
        setloadin("is-loading");
        var formData = new FormData();
        Array.from(files).forEach(f => formData.append('thefiles', f))
		formData.append("thefunction",  "PdfAudit");
        var param = 
        {
          method: 'POST',
          headers: {"Authorization" : cookies.jwt},
          body: formData
        };
        var req = await ApiCall( pythonUrl +"/uploader",param,setCookie,() => {alert("Data Error");})
        try
        {
            var resDict = await req.json();
            console.log(resDict);
            var tables = []
            for(var i=0;i<resDict.length;i++)
            {
                if(resDict[i]["result"] != undefined)
                    tables.push(<React.Fragment key={i}><article className="message is-danger"><div className="message-body">{"Error: "+resDict[i]["result"]}{<b> File: {files[i].name}</b>}</div></article></React.Fragment>)
                else
                {
                    var reskeys = Object.keys(resDict[i]);
                    var resvalues = Object.values(resDict[i]);
                    
                    var bufferTitle = []
                    for(var f=0;f<reskeys.length;f++)
                    {
                        bufferTitle.push(<React.Fragment key={f}><th>{reskeys[f]}</th></React.Fragment>);
                    }
                    var bufferValue= []
                    for(var f=0;f<resvalues.length;f++)
                    {
                        bufferValue.push(<React.Fragment key={f}><td>{resvalues[f]}</td></React.Fragment>);
                    }
                    tables.push(
                        <React.Fragment key={i}>
                            <div className='columns'>
                                <div className='column'>
                                    <table id="resTable" className="table content-tab is-fullwidth">
                                        <thead><tr>{bufferTitle}</tr></thead>
                                        <tbody><tr>{bufferValue}</tr></tbody>
                                    </table>
                                </div>
                                <div className='column is-narrow'>
                                    <article className="message is-success"><div className="message-body">{"Success :)"}{<b> File: {files[i].name}</b>}</div></article>
                                </div>
                            </div>
                        </React.Fragment>);
                
                }
            }
            console.log("in");
            setTable(tables);
            setloadin("");
        }catch(err)
        {
            console.log(err);
            setTable(tables);
            setloadin("");
        }
    }

    return (<>
                <div className="file is-primary is-centered mb-4">
                    <label className="file-label">
                        <input className="file-input" type="file" multiple onChange={(e) => setFiles(e.currentTarget.files)}/>
                        <span className="file-cta">
                            <span className="file-icon"><i className="fas fa-upload"></i></span>
                            <span className="file-label">Upload Single/Multiply Files</span> 
                        </span>
                        <span className="file-name" id="filelbl">{files.length > 0 ? files[0].name : "No files"}</span>
                    </label>
                </div>
                <button className={'button is-success mb-4 '+loadin} onClick={(e) => generateFiles(e)}>Send</button>
                {table}
            </>);
}

export default PdfAuditComponent;