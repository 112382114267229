import React from 'react';
import ReactDOM from 'react-dom';
import store from "./redux/store";
import './index.css';
import {Provider } from "react-redux";
import MainComponent from './Components/MainComponent';
import axios from 'axios';

const MainPage = () =>
{
    return  (
    <>
        <MainComponent />
    </>
   );
}

ReactDOM.render(
    <Provider store={store}>
      <MainPage />
    </Provider>,
  document.getElementById('root')
);
