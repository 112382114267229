import React,{useEffect, useState} from 'react';
import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import { ApiCall } from '../Data/functions';

import { pythonUrl,Nodeurl } from '../Data/functions';
import {useCookies} from 'react-cookie'
const QueriesComponent = () =>
{
    const [catalogList,setCatalogList] = useState([]);
    const [loadin,setloadin] = useState("");
    const [query,setQuery] = useState("");
    const [catalog,setCatalog] = useState("");
    const [isUpdate,setUpdate] = useState(false);
    const [isPortal,setIsPortal] = useState(false);
    const [cookies, setCookie, removeCookies] = useCookies();

    useEffect(() => 
    {
      const func = async () => 
      {
        await fetch ("https://api.bademeister-jan.pro/project/list",
        {
          method:"post",
        })
        .then((response) => response.json())
        .then((data) => 
        {
          var catalogs = []
          for(var i=0;i<data.projects.length;i++)
          {
            catalogs.push(<React.Fragment key={i}><option value={data.projects[i]._id}>{data.projects[i].title}</option></React.Fragment>);
          }
          setCatalogList(catalogs);
        })
      } 
      func();
    },[]);
    const sendQuery = async () =>
    {
      if(catalog != "")
      {
        setloadin("is-loading");
        var param = 
        {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
          body: new URLSearchParams(
          {
              "data_directory" : (window.location.href.includes("localhost") ? "C:/Users/Gad/Documents/GitHub/try/Files/" : "/mnt/disks/birddata2/birddata/")+catalog+"/",
              "query" : query,
              "isPortal" : isPortal ? "T" : "F",
              "isUpdate" : isUpdate ? "T" : "F"
          })
        };
        var blob = await ApiCall(pythonUrl+"/sqlQuery",param,setCookie,(err) => {alert("pandas query isn't valid");setloadin("");console.log(err)})
        blob = await blob.blob();
        if(!isUpdate)
        {
            console.log(blob);
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "query.csv");
            document.body.appendChild(link);
            link.click();
            link.remove();
            setloadin("");
        }
        else
        {
          alert("Done :)");
          setloadin("");
        }
      }
      else  
        alert("Choose Catalog")
    }
    return (<>
                <div className='box'>
                  <div className='columns is-centered'>
                    <div className="column is-narrow">
                      <input id="switchExample" type="checkbox" name="switchExample" onChange={() => setUpdate(!isUpdate)} className="switch"/>
                      <label for="switchExample">  Update query </label>
                    </div>
                    <div className="column is-narrow">
                      <input id="switchExample1" type="checkbox" name="switchExample1" onChange={() => setIsPortal(!isPortal)} className="switch"/>
                      <label for="switchExample1">  Portal parqeut </label>
                    </div>
                  </div>
                  <div className="select mb-3 is-rounded">
                    <select  onChange={(e) => setCatalog(e.currentTarget.value)}>
                      <option value={""}>Select Catalog...</option>
                      {catalogList}
                    </select>
                  </div>
                  <center>{"Enter Pandas Query (The Table is called df):"}</center>
                  <textarea className="textarea mb-3" placeholder='df.loc["Song_Name_9LC"]' onChange={(e) => setQuery(e.currentTarget.value)}></textarea>
                  <button className={'button is-success '+loadin} onClick={() => sendQuery()} >Send</button>
                </div>
            </>);
}

export default QueriesComponent;