import React, { useState } from 'react';
import PdfAuditComponent from './TabsComponents/PdfAuditComponent';
import PdfParsingComponent from './TabsComponents/PdfParsingComponent';
import QueriesComponent from './TabsComponents/QueriesComponent';

const TabsComponent = () =>
{
    const [tabActive,SetTabActive] = useState(0);
    
    return (<>
                <div className="tabs is-toggle is-toggle-rounded is-centered">
                    <ul>
                        <li className={tabActive == 1 ? "is-active" : ""} onClick={() => SetTabActive(1)}><a><span className="icon is-small"><i className="fa-solid fa-sitemap"/></span><span>Pdf Audit</span></a></li>
                        <li className={tabActive == 2 ? "is-active" : ""} onClick={() => SetTabActive(2)}><a><span className="icon is-small"><i className="fa-solid fa-file-pdf"/></span><span>Pdf Parsing</span></a></li>
                        <li className={tabActive == 3 ? "is-active" : ""} onClick={() => SetTabActive(3)}><a><span className="icon is-small"><i className="fa-solid fa-clipboard-question"/></span><span>Queries</span></a></li>
                    </ul>
                </div>
                {tabActive == 1 ? <PdfAuditComponent />
                :tabActive == 2 ? <PdfParsingComponent />
                :tabActive == 3 ? <QueriesComponent />: null}
            </>);
}

export default TabsComponent;