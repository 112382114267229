import { createSlice } from "@reduxjs/toolkit";

export const mySlice = createSlice({
  name: "client",
  initialState:
  {
    
  },
  reducers: {
    
  }
});

export const {} = mySlice.actions;
export default mySlice.reducer;
