import React, { useState } from 'react';
import { ApiCall } from '../Data/functions';

import { pythonUrl } from '../Data/functions';
import {useCookies} from 'react-cookie'

const PdfAuditComponent = () =>
{
    const [files,setFiles] = useState();
    const [table,setTable] = useState(<></>);
    const [loadin,setloadin] = useState("");
    const [cookies, setCookie, removeCookies] = useCookies();

    const generateFiles = async(e) =>
    {
        setloadin("is-loading");

        var formData = new FormData();
        Array.from(files).forEach(f => formData.append('thefiles', f))
		formData.append("thefunction",  "PdfParse");
        var param = 
        {
          method: 'POST',
          headers: {"Authorization" : cookies.jwt},
          body: formData
        };
        var blob = await ApiCall( pythonUrl +"/uploader",param,setCookie,() => {alert("Data Error");})
        try
        {
            blob = await blob.blob();

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "parsing.zip");
            document.body.appendChild(link);
            link.click();
            link.remove();
            setloadin("");
        }catch
        {
            setloadin("");
        }
      
    }

    return (<>
                <div className="file is-primary is-centered mb-4">
                    <label className="file-label">
                        <input className="file-input" type="file" multiple onChange={(e) => e.currentTarget.files.length > 0 ? setFiles(e.currentTarget.files) : null}/>
                        <span className="file-cta">
                            <span className="file-icon"><i className="fas fa-upload"></i></span>
                            <span className="file-label">Upload Single/Multiply Files</span> 
                        </span>
                        <span className="file-name" id="filelbl">{files != undefined ? files[0].name : "No files"}</span>
                    </label>
                </div>
                <button className={'button is-success mb-4 '+loadin} onClick={(e) => generateFiles(e)}>Send</button>
                {table}
            </>);
}

export default PdfAuditComponent;