import React from 'react';
import bitsonicImg from '../Images/company.png';
import TabsComponent from './TabsComponent';
import { authURL, currUrl, pythonUrl } from './Data/functions';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

var version = "V0.0945"
const MainComponent = () =>
{
    const [cookies,setCookie,] = useCookies();
    const checkVersion = async () =>
    {
        await fetch(pythonUrl + "/CheckAdmin",
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Authorization" :  cookies.jwt},
            body: new URLSearchParams({})
        })
        .then(async (res) =>
        {
            console.log(res);
            if(res.status == 401)
            {
                setCookie("redirection",currUrl,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
                alert("re-login required");
                window.location.href = authURL; 
            }
            else
            {
                res = await res.json()
                if(res["version"] != version)
                { 
                    alert("Updated data is available. The BitSonic Portal will now refresh itself.");
                    window.location.reload(false);
                }
            }
        })
        .catch(err => 
        {            
            console.log(err)
            alert("server is closed")
            //window.location.reload(false);
        })
    }
    useEffect(() => checkVersion(),[]) 

    return (<div className='hero is-fullheight'>
                <div className='hero-body'>
                    <div className="container">
                        <center>
                            <img src={bitsonicImg} alt="bitsonic" width="500" height="600"/>
                            <h1 className="title is-1">WebServices UI <span className='tag is-large is-light is-primary'>{version}</span></h1>
                            <h2 className="subtitle is-3">The place for your service</h2>
                            <TabsComponent />
                        </center>
                    </div>
                </div>
           </div>);
}

export default MainComponent;